
import { sxAjax } from '@/commons/ajax';

export default {
    initialState: {
        checkList: []
    },
    getcheckLogs: {
        payload: ({ params, options }) => sxAjax.get('/agent/agentUserCheckRecord/getCheckRecord', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    checkList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

}
