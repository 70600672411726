
/* 增加测试环境 */

const config = {
    test: {
        ajaxDefaults: 'sxapi.btsjkj.cn',
    },
    prod: {
        ajaxDefaults: 'sxapi.btsjkj.cn',
    }
}
// 192.168.11.10:8916
export default {
    official: true,
    ...config[process.env.URL_ENV],
};