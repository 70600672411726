/*
* 菜单数据 返回Promise各式，支持前端硬编码、异步获取菜单数据
* */
import { getLoginUser } from './commons'
export default function getMenus() {
    const loginUser = getLoginUser();
    return Promise.resolve(loginUser ? loginUser.menus : []);
    // TODO 根据userId获取菜单数据 或在此文件中前端硬编码菜单
    // return Promise.resolve([
    //     { key: 'home', local: 'home', text: '首页', icon: 'home', path: '/', order: 2000 },
    //     { key: 'template', local: 'template', text: '模板', icon: 'appstore', path: '/template', order: 1000 },
    //     { key: 'program', local: 'program', text: '节目', icon: 'play-square', path: '/program', order: 999 },
    //     // { key: 'playPlanManage', local: 'playPlanManage', text: '播放计划', icon: 'line-chart', path: '/playPlanList', order: 998 },
    //     { key: 'terminal', local: 'terminal', text: '设备管理', icon: 'desktop', path: '/device', order: 900 },
    //     { key: 'material', local: 'material', text: '素材', icon: 'appstore', path: '/source-material', order: 800 },
    //     { key: 'systemSet', local: 'systemSet', text: '系统设置', icon: 'tool', order: 600 },
    //     {
    //         key: '/systemSet/dpiSet',
    //         parentKey: 'systemSet',
    //         icon: 'money-collect',
    //         local: 'systemSet',
    //         text: '分辨率设置',
    //         path: '/systemSet/dpiSet',
    //     }, {
    //         key: '/systemSet/approveSet',
    //         parentKey: 'systemSet',
    //         icon: 'money-collect',
    //         local: 'systemSet',
    //         text: '审批设置',
    //         path: '/systemSet/approveSet',
    //     },
    //     // { key: 'setting', local: 'setting', text: '设置', icon: 'tool', order: 600 },
    //     // {
    //     //     key: '/setting/terminalfenlei',
    //     //     parentKey: 'setting',
    //     //     icon: 'money-collect',
    //     //     local: 'terminalfenlei',
    //     //     text: '终端分类',
    //     //     path: '/setting/terminalfenlei',
    //     // },
    //     // {
    //     //     key: '/setting/dpiSet',
    //     //     parentKey: 'setting',
    //     //     icon: 'cloud-server',
    //     //     local: 'resolution',
    //     //     text: '分辨率设置',
    //     //     path: '/setting/dpiSet',
    //     // },
    //     {
    //         key: '/setting/company',
    //         //parentKey: 'setting',
    //         parentKey: 'systemSet',
    //         icon: 'transaction',
    //         local: 'company',
    //         text: '公司设置',
    //         path: '/setting/company',
    //     },

    //     { key: 'userorganization', local: 'userorganization', text: '用户管1理', icon: 'file-done', order: 550 },
    //     {
    //         key: '/userorganization/userList',
    //         parentKey: 'userorganization',
    //         icon: 'file-done',
    //         local: 'userList',
    //         text: '用户列表',
    //         path: '/userorganization/userList',
    //     },
    //     {
    //         key: '/userorganization/roleList',
    //         parentKey: 'userorganization',
    //         icon: 'file-done',
    //         local: 'roleList',
    //         text: '角色列表',
    //         path: '/userorganization/roleList',
    //     },
    //     {
    //         key: '/userorganization/loginLog',
    //         parentKey: 'userorganization',
    //         icon: 'file-done',
    //         local: 'loginLog',
    //         text: '登录日志',
    //         path: '/userorganization/loginLog',
    //     },
    //     {
    //         key: '/userorganization/operationLog',
    //         parentKey: 'userorganization',
    //         icon: 'file-done',
    //         local: 'operationLog',
    //         text: '操作日志',
    //         path: '/userorganization/operationLog',
    //     },
    //     { key: 'programPlayLogs', local: 'programPlayLogs', text: '播放日志', path: '/programPlayLogs', icon: 'lock', order: 400 },
    //     { key: 'usercenter', local: 'usercenter', text: '用户中心', icon: 'user', path: '/user-center', order: 500 },
    //     { key: 'assetsmanagement', local: 'assetsmanagement', text: '资产管理', icon: 'lock', order: 400 },
    //     {
    //         key: '/assetsmanagement/asset',
    //         parentKey: 'assetsmanagement',
    //         icon: 'money-collect',
    //         local: 'asset',
    //         text: '我的资产',
    //         path: '/assetsmanagement/asset',
    //     },
    //     {
    //         key: '/assetsmanagement/flowsDetails',
    //         parentKey: 'assetsmanagement',
    //         icon: 'money-collect',
    //         local: 'asset',
    //         //text: '流量明细',
    //         text: '我的流量',
    //         path: '/assetsmanagement/flowsDetails',
    //     },
    //     {
    //         key: '/assetsmanagement/orderForm',
    //         parentKey: 'assetsmanagement',
    //         icon: 'money-collect',
    //         local: 'asset',
    //         text: '订单明细',
    //         path: '/assetsmanagement/ordeForm',
    //     },

    //     {
    //         key: '/assetsmanagement/disk',
    //         parentKey: 'assetsmanagement',
    //         icon: 'cloud-server',
    //         local: 'disk',
    //         text: '我的磁盘空间',
    //         path: '/assetsmanagement/disk',
    //     },

    // ]);
}
