
import { sxAjax } from '@/commons/ajax';

export default {
    initialState: {
        loading: false,// 全局loading
        deviceId: null,//设备id  
        adminList: [],
        deviceEditVisible: false,
        device: null,//设备信息
        code: 0,
        logList: []

    },
    getDevices: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/getDevices', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getDeviceGroups: {//终端分类列表查询
        payload: ({ params, options }) => sxAjax.get('/agent/deviceGroup/getDeviceGroups', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    addProgramBindDevice: {//节目绑定终端/（节目发布）
        payload: ({ params, options }) => sxAjax.get('/agent/programManage/addProgramBindDevice', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getDpis: {//分辨率
        payload: ({ params, options }) => sxAjax.get('/agent/programDpiManage/getDpis', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getProgramGroups: {//节目分组
        payload: ({ params, options }) => sxAjax.get('/agent/programManage/getProgramGroups', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    editAddProgram: {//新建节目
        payload: ({ params, options }) => sxAjax.post('/agent/programManage/editAddProgram', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    viewLog: {//日志
        payload: ({ params, options }) => sxAjax.get('/admin/log-manage/getProgramPlayLogs', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },


    getPublishList: {
        payload: ({ params, options }) => sxAjax.get('/agent/publish/getPublishList', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

    getProgramPlayLogs: {
        payload: ({ params, options }) => sxAjax.get('/agent/programPlayPlanLog/getProgramPlayLogs', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    logList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

    getProgramPlayLogsEdit: {
        payload: ({ params, options }) => sxAjax.post('/agent/programPlayPlanLog/edit', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    logList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

    deletedProgramPlayLogs: {
        payload: ({ params, options }) => sxAjax.post('/agent/programPlayPlanLog/deleted', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    updatePlayLogPublishStatus: {
        payload: ({ params, options }) => sxAjax.post('/agent/programPlayPlanLog/updatePlayLogPublishStatus', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

    exportDeviceExcel: {
        payload: ({ params, options }) => sxAjax.get('/agent/programPlayPlanLog/deviceNameExport', params, options).then(function (resp) {
            // return new Promise((resolve, reject) => {
            //     if (resp) {
            //         var a = document.createElement('a'); //创建一个<a></a>标签
            //         a.href = URL.createObjectURL(resp); // response is a blob
            //         a.download = "设备列表.xlsx";  //文件名称
            //         a.style.display = 'none';
            //         document.body.appendChild(a);
            //         a.click();
            //         a.remove()
            //         resolve()
            //     } else {
            //         reject()
            //     }
            // });
        })
    },

}
