import { sxAjax } from '@/commons/ajax';

export default {

    pageList: {
        payload: ({ params, options }) => sxAjax.get('agent/programDpiManage/getDpis', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                   adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
//agent/programDpiManage/delAddDpis

    }, editOrAdd: {
        payload: ({ params, options }) => sxAjax.get('agent/programDpiManage/editAddDpis', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                   adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }


    }, del: {
        payload: ({ params, options }) => sxAjax.get('agent/programDpiManage/delAddDpis', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                   adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }


    }

}


 
