import { sxAjax } from '@/commons/ajax';

export const PAGE_FRAME_LAYOUT = {
    TOP_SIDE_MENU: 'top-side-menu',
    TOP_MENU: 'top-menu',
    SIDE_MENU: 'side-menu',
};

export default {
    initialState: {
        pageFrameLayout: PAGE_FRAME_LAYOUT.SIDE_MENU,
        pageHeadFixed: true,
        pageHeadShow: true,
        tabsShow: true,
        loading: false
    },

    syncStorage: true, // 全部同步到localStorage中

    setPageFrameLayout: (pageFrameLayout) => ({ pageFrameLayout }),
    setPageHeadFixed: (pageHeadFixed) => ({ pageHeadFixed: !!pageHeadFixed }),
    showPageHead: (pageHeadShow, state) => ({ pageHeadShow: !!pageHeadShow, pageHeadFixed: pageHeadShow ? state.pageHeadFixed : false }),
    showTabs: tabsShow => ({ tabsShow }),

    //组织结构
    getOrganizeTree: {
        payload: ({ params, options }) => sxAjax.get('/agent/userOrganize/getOrganizeTree', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //删除/批量删除组织结构
    delOrganize: {
        payload: ({ params, options }) => sxAjax.post('/agent/userOrganize/delOrganize', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //编辑组织结构
    editOrganize: {
        payload: ({ params, options }) => sxAjax.post('/agent/userOrganize/editOrganize', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //添加组织结构
    addOrganize: {
        payload: ({ params, options }) => sxAjax.get('/agent/userOrganize/addOrganize', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //组织人员记录
    getOrganizeAgentUsers: {
        payload: ({ params, options }) => sxAjax.get('/agent/userOrganize/getOrganizeAgentUsers', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //删除用户
    delAddOrganizeAgentUser: {
        payload: ({ params, options }) => sxAjax.get('/agent/userOrganize/delAddOrganizeAgentUser', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //修改密码
    deitOrganizePassword: {
        payload: ({ params, options }) => sxAjax.post('/agent/userOrganize/deitOrganizePassword', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //添加/编辑组织用户
    editAddOrganizeAgentUser: {
        payload: ({ params, options }) => sxAjax.post('/agent/userOrganize/editAddOrganizeAgentUser', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },



    //查看角色列表
    getAgentRoles: {
        payload: ({ params, options }) => sxAjax.get('/agent/userOrganize/getAgentRoles', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //查看角色列表
    delAgentRole: {
        payload: ({ params, options }) => sxAjax.get('/agent/userOrganize/delAgentRole', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //角色编辑
    editAgentRole: {
        payload: ({ params, options }) => sxAjax.post('/agent/userOrganize/editAgentRole', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //角色权限查询
    getPermissionsRecAll: {
        payload: ({ params, options }) => sxAjax.get('/agent/userOrganize/getPermissionsRecAll', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //角色授权
    addRolePermission: {
        payload: ({ params, options }) => sxAjax.post('/agent/userOrganize/addRolePermission', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    }, 
    //公司基本信息查看
    getCompanyInfo: {
        payload: ({ params, options }) => sxAjax.get('/agent/companyInfo/getCompanyInfo', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    }, 
    //角色添加
    roleAdd: {
        payload: ({ params, options }) => sxAjax.post('/agent/userOrganize/roleAdd', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //日志查看
    userLog: {
        payload: ({ params, options }) => sxAjax.get('/agent/log-manage/getAgentLogs', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
}
