import { sxAjax } from '@/commons/ajax';
export default {
    initialState: {
        loading: false,             // 全局loading
    },
    // 获取模版商城类型
    getTemplateTypes: {
        payload: ({ params, options }) => sxAjax.get('/common/status/getTemplateTypes', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 商城列表查看
    getProgramTemplates: {
        payload: ({ params, options }) => sxAjax.get('/agent/templateMall/getProgramTemplates', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 通用商城获取分辨率
    getProgramDpis: {
        payload: ({ params, options }) => sxAjax.get('/common/status/getProgramDpis', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getProgramMyDpis: {
        payload: ({ params, options }) => sxAjax.get('/agent/programDpiManage/getDpis', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 查询分组列表
    getProgramGroups: {
        payload: ({ params, options }) => sxAjax.get('/agent/programManage/getProgramGroups', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 使用商城模版
    useProgramTemplate: {
        payload: ({ params, options }) => sxAjax.post('/agent/templateMall/useProgramTemplate', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 我的模板
    getAgentUserProgramTemplates: {
        payload: ({ params, options }) => sxAjax.get('/agent/templateMall/getAgentUserProgramTemplates', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 删除模板记录
    delMyTemplate: {
        payload: ({ params, options }) => sxAjax.post('/agent/templateMall/delMyTemplate', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 模板发布到商城
    publishTemplateMall: {
        payload: ({ params, options }) => sxAjax.post('/agent/templateMall/publishTemplateMall', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 从模板商城下架
    updownFromMall: {
        payload: ({ params, options }) => sxAjax.post('/agent/templateMall/updownFromMall', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 模板订单记录
    getOrderTemplates: {
        payload: ({ params, options }) => sxAjax.get('/agent/templateMall/getOrderTemplates', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //使用我的模版
    useMyProgramTemplate: {
        payload: ({ params, options }) => sxAjax.post('/agent/templateMall/useMyProgramTemplate', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

    getProgramTemplateLogs: {
        payload: ({ params, options }) => sxAjax.get('/agent/templateLog/getProgramTemplateLogs', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

    cancelReview: {
        payload: ({ params, options }) => sxAjax.post('/agent/templateLog/cancelReview', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

    checkUseProgramTemplate: {
        payload: ({ params, options }) => sxAjax.get('/agent/programManage/checkUseProgramTemplate', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    console.log('errrerrrerrrerrrerrrerrr')
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },



}