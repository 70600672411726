import { sxAjax } from '@/commons/ajax';


// 进行本地存储同步，syncState中的同步是区分用户的，会导致未登录的页面与登录的页面有差异

export default {
    initialState: {
        loading: false,// 全局loading
        deviceId:null,//设备id  
        adminList: [],   
        deviceEditVisible: false ,
        device : null,//设备信息
        code:0

    },
    //亮度策略查询列表
    getLuminancePolicys: {
        payload: ({ params, options }) => sxAjax.get('agent/devicePolicyLuminance/getLuminancePolicys', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //编辑/添加亮度策略
    editAddLuminancePolicy: {
        payload: ({ params, options }) => sxAjax.post('agent/devicePolicyLuminance/editAddLuminancePolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //批量删除 /单个删除策略
    delLuminancePolicy: {
        payload: ({ params, options }) => sxAjax.post('agent/devicePolicyLuminance/delLuminancePolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

    //查询亮度周策略
    getLuminanceWeekPolicy: {
        payload: ({ params, options }) => sxAjax.get('agent/devicePolicyLuminance/getLuminanceWeekPolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //查询亮度特殊日期策略
    getLuminanceSpecialPolicy: {
        payload: ({ params, options }) => sxAjax.get('agent/devicePolicyLuminance/getLuminanceSpecialPolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //编辑亮度周策略
    editLuminanceWeekPolicy: {
        payload: ({ params, options }) => sxAjax.post('agent/devicePolicyLuminance/editLuminanceWeekPolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //编辑亮度特殊日期策略
    editLuminanceSpecialPolicy: {
        payload: ({ params, options }) => sxAjax.post('agent/devicePolicyLuminance/editLuminanceSpecialPolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //添加策略周策略
    addLuminanceWeekPolicy: {
        payload: ({ params, options }) => sxAjax.post('agent/devicePolicyLuminance/addLuminanceWeekPolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //添加亮度特殊日期策略
    addLuminanceSpecialPolicy: {
        payload: ({ params, options }) => sxAjax.post('agent/devicePolicyLuminance/addLuminanceSpecialPolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //删除 亮度 周策略
    delLuminanceWeekPolicy: {
        payload: ({ params, options }) => sxAjax.post('agent/devicePolicyLuminance/delLuminanceWeekPolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //删除 亮度 特殊 日期 策略
    delLuminanceSpecialPolicy: {
        payload: ({ params, options }) => sxAjax.post('agent/devicePolicyLuminance/delLuminanceSpecialPolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

    //亮度策略 策略的id
    getPolicyLuminances: {
        payload: ({ params, options }) => sxAjax.get('agent/device/getPolicyLuminances', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
}
