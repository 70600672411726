// 此文件是通过脚本生成的，直接编辑无效！！！

// 不需要导航框架的页面路径
export const noFrames = [
    '/login',
    '/wechatlogin',
    '/download',
];

// 不需要登录就可以访问的页面路径
export const noAuths = [
    '/login',
    '/wechatlogin',
    '/download',
];

// 需要keep alive 页面
export const keepAlives = [
    {
        path: '/iframe_page_/:src',
        keepAlive: true,
    },
    {
        path: '/login',
        keepAlive: false,
    },
    {
        path: '/wechatlogin',
        keepAlive: false,
    },
    {
        path: '/download',
        keepAlive: false,
    },
];

// 页面路由配置
export default [
    {
        path: '/assetsmanagement/asset',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/assetsmanagement/asset/index.jsx'),
    },
    {
        path: '/assetsmanagement/device',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/assetsmanagement/device/index.jsx'),
    },
    {
        path: '/assetsmanagement/disk',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/assetsmanagement/disk/index.jsx'),
    },
    {
        path: '/assetsmanagement',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/assetsmanagement/index.jsx'),
    },
    {
        path: '/assetsmanagement/flowsDetails',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/assetsmanagement/order/flowsDetails.jsx'),
    },
    {
        path: '/assetsmanagement/ordeForm',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/assetsmanagement/order/orderForm.jsx'),
    },
    {
        path: '/checkManage/checkUserLogs',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/checkManage/checkHistory/index.jsx'),
    },
    {
        path: '/checkManage/checkLogs',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/checkManage/history/index.jsx'),
    },
    {
        path: '/checkManage',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/checkManage/index.jsx'),
    },
    {
        path: '/checkManage/programCheck',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/checkManage/programCheck/index.jsx'),
    },
    {
        path: '/checkManage/programCheck',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/checkManage/sourceCheck/index.jsx'),
    },
    {
        path: '/systemSet/dpiSet',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/device-manage/dpi-set/index.jsx'),
    },
    {
        path: '/device',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/device-manage/index.jsx'),
    },
    {
        path: '/example/antd/user-avatar',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/examples/antd/user-avatar/index.jsx'),
    },
    {
        path: '/playPlanList111',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/examples/index copy.jsx'),
    },
    {
        path: '/example11',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/examples/index.jsx'),
    },
    {
        path: '/user-inform',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/header-us/inform.jsx'),
    },
    {
        path: '/user-manual',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/header-us/manual.jsx'),
    },
    {
        path: '/',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/home/index.jsx'),
    },
    {
        path: '/iframe_page_/:src',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/iframe/index.jsx'),
    },
    {
        path: '/logList',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/log-list/index.jsx'),
    },
    {
        path: '/login',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/login/index.jsx'),
    },
    {
        path: '/wechatlogin',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/login/wechatLogin.jsx'),
    },
    {
        path: '/download',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/mobileDownload/index.jsx'),
    },
    {
        path: '/playPlanList',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/play-plan-manage/index.jsx'),
    },
    {
        path: '/programPlayLogs',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/program-play-logs/index.jsx'),
    },
    {
        path: '/program',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/program/index.jsx'),
    },
    {
        path: 'program',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/program/program/index.jsx'),
    },
    {
        path: '/financialManage/orderTemplates',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/program/program/viewLog.jsx'),
    },
    {
        path: '/releaseManagement',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/releaseManagement/index.jsx'),
    },
    {
        path: '/roles',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/roles/index.jsx'),
    },
    {
        path: '/assetsmanagement/asset',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/setting/assetsmanagement/asset/index.jsx'),
    },
    {
        path: '/assetsmanagement/device',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/setting/assetsmanagement/device/index.jsx'),
    },
    {
        path: '/assetsmanagement/disk',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/setting/assetsmanagement/disk/index.jsx'),
    },
    {
        path: '/assetsmanagement',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/setting/assetsmanagement/index.jsx'),
    },
    {
        path: '/assetsmanagement/flowsDetails',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/setting/assetsmanagement/order/flowsDetails.jsx'),
    },
    {
        path: '/assetsmanagement/ordeForm',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/setting/assetsmanagement/order/orderForm.jsx'),
    },
    {
        path: '/setting/dpiSet',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/setting/dpiSet/index.jsx'),
    },
    {
        path: '/setting',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/setting/index.jsx'),
    },
    {
        path: '/setting/strategy',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/setting/strategy/index.jsx'),
    },
    {
        path: '/systemSet/approveSet',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/setting/system-set/approval-set/index.jsx'),
    },
    {
        path: '/setting/company',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/setting/system-set/company/index.jsx'),
    },
    {
        path: '/systemSet',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/setting/system-set/index.jsx'),
    },
    {
        path: '/setting/terminalfenlei',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/setting/terminal/index.jsx'),
    },
    {
        path: '/userorganization',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/setting/userorganization/index.jsx'),
    },
    {
        path: '/source-material',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/source-material/index.jsx'),
    },
    {
        path: '/systemSet/approveSet',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/system-set/approval-set/index.jsx'),
    },
    {
        path: '/setting/company',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/system-set/company/index.jsx'),
    },
    {
        path: '/systemSet',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/system-set/index.jsx'),
    },
    {
        path: '/template',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/template/index.jsx'),
    },
    {
        path: '/user-center',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/user-center/index.jsx'),
    },
    {
        path: '/userorganization',
        component: () => import('F:/XINFA/web/yongHuHouTai/src/pages/userorganization/index.jsx'),
    },
];
