import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.less';
import config from '@/commons/config-hoc';
@config({
    ajax: true
})
export default class Logo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logoImg: null
        };
    }
    static propTypes = {
        min: PropTypes.bool,
    };

    static defaultProps = {
        title: 'React Web',
        min: false,

    };

    componentDidMount() {
        this.getCommonConfigs()
    }

    getCommonConfigs = () => {
        this.props.action.sysConfig.getCommonConfigs({
            params: { type: '1' }, // 网站信息
            options: { method: "get", withCredentials: false, contentType: "application/json" },
            onResolve: (data) => {
                let webInfo = {
                    'copyright_info': data.copyright_info,
                    'user_logo': data.user_logo
                }
                this.setState({
                    logoImg: data.user_logo
                })
                window.localStorage.setItem('webInfo', JSON.stringify(webInfo))
            }
        });
    }
    render() {
        //const { min, title, ...others } = this.props;
        const { logoImg } = this.state
        return (
            <div styleName="logo">
                {/* <img src={logoImg} alt="logo" /> */}
            </div>
        );
    }
}
