
import { sxAjax } from '@/commons/ajax';

export default {
    initialState: {
        loading: false,// 全局loading
        deviceId: null,//设备id  
        adminList: [],
        deviceEditVisible: false,
        device: null,//设备信息
        code: 0

    },
    getStsToken: {
        payload: ({ params, options }) => sxAjax.post('/aliOssRam/auth/getStsToken', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getFileConvertAuthToken: {
        payload: ({ params, options }) => sxAjax.post('/aliOssRam/auth/getFileConvertAuthToken', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },//文档类型素材资源添加
    addConvertResource: {
        payload: ({ params, options }) => sxAjax.post('/agent/userResource/addConvertResource', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }


            },
            complete: (state, action) => ({ loading: false }),
        }
    }, uploadWordPdf: {
        payload: ({ params, options }) => sxAjax.post('/file-convert/main/pdf-word/toImgQueue', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    }, getUploadResult: {
        payload: ({ params, options }) => sxAjax.get('/file-convert/main/pdf-word/getUploadResult', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

}
