import React, { Component } from 'react';
import { getLoginUser } from '@/commons';
import { Modal, Form, message, Row, Col, Button } from 'antd';
import config from '@/commons/config-hoc';
import { FormElement } from '@/library/antd';
import './style.less';
@config({
    ajax: true,
    connect(state) {
        return {};
    }
})
@Form.create()
export default class UserMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: null,//内容
            loading: false
        };
    }

    addContentMessage = () => {
        const { onOk, form: { validateFieldsAndScroll } } = this.props;
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                const params = {
                    agentUserId: getLoginUser().id,
                    title: values.title,
                    content: values.content
                };
                this.props.action.system.addContentMessage({
                    params: params,
                    options: { method: "POST", withCredentials: false, contentType: "application/json" },
                    onResolve: (data) => {
                        onOk();
                        message.info('操作成功！')
                    },
                    onReject: (data) => {
                        message.error(data);
                    },
                });
            }
        });
    }

    FormElement = (props) => <FormElement form={this.props.form} labelWidth={100} {...props} />;
    render() {
        const { visible } = this.props;
        const FormElement = this.FormElement;
        return (
            <Modal
                maskClosable={false}
                destroyOnClose
                width="22%"
                visible={visible}
                // bodyStyle={{ padding: 0, height: '460PX' }}
                onOk={() => this.props.onOk()}
                onCancel={() => this.props.onOk()}
                footer={null}
            >
                <Row>
                    <Col span={24} styleName='userMessage-title' >
                        <Col span={24} >
                            我要留言
                        </Col>
                    </Col>
                    <Col span={24} styleName='userMessage-content' >
                        <Form>
                            <Row>
                                <Col span={24}>
                                    <FormElement
                                        placeholder="标题"
                                        field="title"
                                        decorator={{
                                            initialValue: undefined,
                                            rules: [
                                                { required: true, message: '请输入标题！' }
                                            ],
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <FormElement
                                        placeholder="150字以内"
                                        field="content"
                                        type='textarea'
                                        maxlength={150}
                                        autoSize={{ minRows: 6, maxRows: 8 }}
                                        decorator={{
                                            initialValue: undefined,
                                            rules: [
                                                { required: true, message: '150字以内' }
                                            ],
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row >
                                <Col span={24} justify='center' align='middle'>
                                    <Button type="primary" onClick={this.addContentMessage} >提交</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>

                </Row>
            </Modal>
        );
    }
}
