import { sxAjax } from '@/commons/ajax';
export default {

    findDiskCountByType: {
        payload: ({ params, options }) => sxAjax.get('/agent/userAssetCenter/findDiskCountByType', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },getDiskPriceType: {
        payload: ({ params, options }) => sxAjax.get('common/status/getDiskPriceType', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },buyDisk: {
        payload: ({ params, options }) => sxAjax.get('agent/userAssetCenter/buyDisk', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

}


 
