import { sxAjax } from '@/commons/ajax';
export default {
    initialState: {
        loading: false,             // 全局loading
    },
    // 设备分组
    getCompanyDeviceTree: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/getCompanyDeviceTree', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 设备终端查询
    getDevices: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/getDevices', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 终端删除
    delLogicDevices: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/delLogicDevices', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 编辑终端
    editDevices: {
        payload: ({ params, options }) => sxAjax.post('/agent/device/editDevices', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //查询终端详情
    getDeviceById: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/getDeviceById', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 终端续费获取套餐类型
    getDevicePriceCombos: {
        payload: ({ params, options }) => sxAjax.get('/common/status/getDevicePriceCombos', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    }

}