
import { sxAjax } from '@/commons/ajax';

//const primaryColor = getItem('primaryColor') || theme['@primary-color'];

export default {
    initialState: {
        loading: false,// 全局loading
        deviceId: null,//设备id  
        adminList: [],
        deviceEditVisible: false,
        device: null,//设备信息
        code: 0

    },
    pageList: {
        payload: ({ params, options }) => sxAjax.get('/agent/programManage/getPrograms', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }


    },
    //删除
    delData: {
        payload: ({ params, options }) => sxAjax.get('/agent/programManage/delProgram', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {

                return {
                    adminList: payload
                }

            },
            complete: (state, action) => ({ loading: false }),
        }

    },
    publishProgram: {
        payload: ({ params, options }) => sxAjax.get('/agent/programManage/addProgramBindDevice', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    device: payload
                }


            },
            complete: (state, action) => ({ loading: false }),
        }
    }
    ,
    editData: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/editDevices', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {

                return {
                    code: payload.code
                }


            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getProgramGroups: {
        payload: ({ params, options }) => sxAjax.get('/agent/programManage/getProgramGroups', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    delProgramGroups: {
        payload: ({ params, options }) => sxAjax.get('/agent/programManage/delProgramGroups', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {

                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    editAddProgramGroups: {
        payload: ({ params, options }) => sxAjax.post('/agent/programManage/editAddProgramGroups', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload?.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getByProgramId: {
        payload: ({ params, options }) => sxAjax.get('/agent/agentUserProgramResource/getByProgramId', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },//复制节目
    copyProgram: {
        payload: ({ params, options }) => sxAjax.post('/agent/programManage/copyProgram', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {

                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },//审核节目
    programCheck: {
        payload: ({ params, options }) => sxAjax.post('/agent/programManage/programCheck', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {

                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },//节目发布
    programPublish: {
        payload: ({ params, options }) => sxAjax.post('/agent/programManage/programPublish', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {

                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },//判断节目是否可以下载
    isCanDownload: {
        payload: ({ params, options }) => sxAjax.post('/agent/programManage/isCanDownload', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {

                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    programShare: {
        payload: ({ params, options }) => sxAjax.post('/agent/programManage/shareProgram', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.msg);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },


    updateProgramPlayStatus: {//节目发布状态查看
        payload: ({ params, options }) => sxAjax.post('/agent/programManage/updateProgramPlayStatus', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

    programPlayPlanLogEdit: {//节目发布状态查看
        payload: ({ params, options }) => sxAjax.post('/agent/programPlayPlanLog/edit', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    bathMoveGroup: {//节目转移分组
        payload: ({ params, options }) => sxAjax.post('/agent/programManage/v2/bathMoveGroup', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    bathRecallProgram: {//节目撤回
        payload: ({ params, options }) => sxAjax.post('/agent/programManage/v2/bathRecallProgram', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

}
