//import i18n, { defaultLang } from '../i18n';
//import theme from "@/theme";
import { sxAjax } from '@/commons/ajax';
//import { message } from 'antd';
//import { setLoginUser } from '@/commons';

export default {

    getCompanyInfo: {
        payload: ({ params, options }) => sxAjax.get('agent/companyInfo/getCompanyInfo', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                   adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }


    }, editCompanyInfo: {
        payload: ({ params, options }) => sxAjax.post('agent/companyInfo/editCompanyInfo', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                   adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }


    }, 

}


 
