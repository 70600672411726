
import { sxAjax } from '@/commons/ajax';

export default {
    initialState: {
        loading: false,// 全局loading
        deviceId:null,//设备id  
        adminList: [],   
        deviceEditVisible: false ,
        device : null,//设备信息
        code:0

    },
    pageList: {
                payload: ({ params, options }) => sxAjax.get('agent/devicePolicyVolume/getVolumePolicys', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            },//批量删除 /单个删除策略
            delVolumePolicy: {
                payload: ({ params, options }) => sxAjax.get('agent/devicePolicyVolume/delVolumePolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            },editAddVolumePolicy: {
                payload: ({ params, options }) => sxAjax.get('agent/devicePolicyVolume/editAddVolumePolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
    },
            //查询音量周策略
            getVolumeWeekPolicy: {
                payload: ({ params, options }) => sxAjax.get('agent/devicePolicyVolume/getVolumeWeekPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }, addVolumeWeekPolicy: {
                payload: ({ params, options }) => sxAjax.get('agent/devicePolicyVolume/addVolumeWeekPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            },delVolumeWeekPolicy: {
                payload: ({ params, options }) => sxAjax.get('agent/devicePolicyVolume/delVolumeWeekPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }, editVolumeWeekPolicy: {
                payload: ({ params, options }) => sxAjax.get('agent/devicePolicyVolume/editVolumeWeekPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }, getVolumeSpecialPolicy: {
                payload: ({ params, options }) => sxAjax.get('agent/devicePolicyVolume/getVolumeSpecialPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }, addVolumeSpecialPolicy: {
                payload: ({ params, options }) => sxAjax.get('agent/devicePolicyVolume/addVolumeSpecialPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }, editVolumeSpecialPolicy: {
                payload: ({ params, options }) => sxAjax.get('agent/devicePolicyVolume/editVolumeSpecialPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            },
            delVolumeSpecialPolicy: {
                payload: ({ params, options }) => sxAjax.get('agent/devicePolicyVolume/delVolumeSpecialPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            },
            //音量策略 策略的id
            getPolicyVolumes: {
                payload: ({ params, options }) => sxAjax.get('agent/device/getPolicyVolumes', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            },
            
           // 
}
