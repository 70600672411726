import React, { Component } from 'react';
import { getLoginUser } from '@/commons';
import { connect } from '@/models';
import { toLogin } from '@/commons';
import { UserAvatar } from '@/library/antd';
import './style.less';
import { Popover, Button } from 'antd';
//import Link from '../page-link';
//import {Link , Route} from 'react-router-dom';
import { ROUTE_BASE_NAME } from '@/router/AppRouter';
@connect(state => ({ local: state.system.i18n }))
export default class HeaderUser extends Component {
    static defaultProps = {
        theme: 'default',
    };
    state = {
        passwordVisible: false,
        aboutUsVisible: false,
        courseVisible: false,
        leavingMessageVisible: false,
        userName: "信息发布系统"
    };

    userCenter = () => {
        window.location.href = ROUTE_BASE_NAME + '/user-center';
        //console.log(this.props)
    };



    render() {
        const user = getLoginUser() || {};
        const name = user.name;
        const avatar = user.avatar;
        const content = (
            <div styleName="hedaer-user-menu">
                <p onClick={this.userCenter}>用户中心</p>
                <p onClick={
                      ()=>{
                        toLogin();
                        window.localStorage.setItem('webInfo', '');
                      }}>
                退出登录</p>
            </div >
        );
        return (
            // <Link to={{
            //     pathname: '/user-center',
            //     state: { from: 'menu' }
            // }}>
            //<Link to='/user-center'>
            <Popover content={content} >
                <div
                    // onClick={this.userCenter}
                    style={{ width: '100%', height: '98px', margin: ' auto', textAlign: 'center', paddingTop: '30px', marginBottom: '30px' }}
                >

                    {avatar ? (
                        <UserAvatar style={{ cursor: 'pointer' }} size="large" src={avatar} />
                    ) : (
                        <UserAvatar style={{ cursor: 'pointer' }} size="large" name={name} />
                    )}
                    <div title={name} style={{ marginTop: '10px', color: '#fff', fontSize: '13px', fontWeight: 900, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{name}</div>

                </div>
            </Popover>
            //</Link>
        );
    }
}
