import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon, Divider, Badge, Popconfirm } from 'antd';
import { connect } from '@/models/index';
import { renderNode } from '@/library/utils/tree-utils';

import Link from '../page-link';
import { getLoginUser } from '../../commons';
import { threeMenu } from '../../commons/constant';
import { toLogin } from '@/commons';

import './style.less';

const { SubMenu } = Menu;

@connect(state => {
    const { hasCheck } = state.common;
    return {
        hasCheck,
    };
})
export default class SideMenu extends Component {
    static propTypes = {
        dataSource: PropTypes.array,    // 菜单数据
        theme: PropTypes.string,        // 主题
        collapsed: PropTypes.bool,      // 是否收起
        openKeys: PropTypes.array,      // 打开菜单keys
        selectedKeys: PropTypes.array,  // 选中菜单keys
        onOpenChange: PropTypes.func,   // 菜单打开关闭时触发
    };

    static defaultProps = {
        dataSource: [],
        theme: 'dark',
        collapsed: false,
        openKeys: [],
        selectedKeys: [],
        onOpenChange: () => true,
    };

    handleOpenChange = (openKeys) => {
        this.props.onOpenChange(openKeys);
    };

    renderMenus() {
        const { dataSource, hasCheck } = this.props;
        const { menusChildrens } = getLoginUser();
        
        if (dataSource && dataSource.length) {
            return renderNode(dataSource, (item, children) => {
                const {
                    key,
                    path,
                    text,
                    icon,
                    target,
                    url,
                } = item;

                let title = <span>{text}</span>;

                if (icon) {
                    if (path === '/checkManage') {
                        title = <span><Badge count={hasCheck ? hasCheck.totalNum : 0} offset={[-5, 0]} dot={true}><Icon type={icon} /></Badge><span>{text}</span></span>;
                    } else {
                        title = <span><Icon type={icon} /><span>{text}</span></span>;
                    }
                }

                if (threeMenu.includes(path)) {
                    const findMenu = menusChildrens.find(item => item.path === path) || {};
                    const { children } = findMenu;
                    return (
                        <SubMenu key={path} title={title}>
                            {children.map(({ path, name }) => (
                                <Menu.Item key={path}>
                                    <Link to={{
                                        pathname: path,
                                        state: { from: 'menu' }
                                    }}>
                                        {name}
                                    </Link>
                                </Menu.Item>
                            ))}
                        </SubMenu>
                    );
                }

                return (
                    <Menu.Item key={key}>
                        {target ? (
                            <a href={url} target={target}>
                                {title}
                            </a>
                        ) : (
                            <Link to={{
                                pathname: path,
                                state: { from: 'menu' }
                            }}>
                                {title}
                            </Link>
                        )}
                    </Menu.Item>
                );
            });
        }
        return null;
    }

    render() {
        let { theme, collapsed, openKeys, selectedKeys } = this.props;
        return (
            <div>
                <div styleName="side-menu">
                    <Menu
                        // {...menuProps}
                        openKeys={openKeys}
                        selectedKeys={selectedKeys}
                        mode="inline"
                        theme={theme}
                        inlineCollapsed={collapsed}
                        onOpenChange={this.handleOpenChange}
                    >
                        {this.renderMenus()}
                    </Menu>
                    {/* <Divider type="horizontal" style={{ border: '0.1px solid #343434' }} />

                    <Popconfirm
                        placement="right"
                        title={"确定退出登录吗?"}
                        onConfirm={() => {
                            toLogin();
                            window.localStorage.setItem('webInfo', '');
                        }}
                        okText="确定"
                        cancelText="取消"
                    >
                        <div style={{ "color": "white", "marginLeft": "30px", "width": "85px" }}>
                            <Icon type="poweroff" />
                            <span style={{ "marginLeft": "5px" }}>退出登录</span>
                        </div>
                    </Popconfirm> */}

                </div>

            </div>

        );
    }

}
