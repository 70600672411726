
import { sxAjax } from '@/commons/ajax';


// 进行本地存储同步，syncState中的同步是区分用户的，会导致未登录的页面与登录的页面有差异

export default {
    initialState: {
        loading: false,// 全局loading
    },
    pageList: {
                payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyDownload/getDownloadPolicys', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            },editDownloadWeekPolicy: {
                payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyDownload/editDownloadWeekPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }, addDownloadWeekPolicy: {
                payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyDownload/addDownloadWeekPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }, getDownloadWeekPolicy: {
                payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyDownload/getDownloadWeekPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }, editAddDownloadPolicy: {
                payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyDownload/editAddDownloadPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }, delDownloadWeekPolicy: {
                payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyDownload/delDownloadWeekPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }, delDownloadPolicy: {
                payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyDownload/delDownloadPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }, //查询下载特殊日期策略
            getDownloadSpecialPolicy: {
                payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyDownload/getDownloadSpecialPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }, //添加下载特殊日期策略
            addDownloadSpecialPolicy: {
                payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyDownload/addDownloadSpecialPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }, //编辑下载特殊日期策略
            editDownloadSpecialPolicy: {
                payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyDownload/editDownloadSpecialPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }, //删除下载特殊日期策略
            delDownloadSpecialPolicy: {
                payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyDownload/delDownloadSpecialPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            
            },//策略的id 下载策略
            getPolicyDownloads: {
                payload: ({ params, options }) => sxAjax.get('/agent/device/getPolicyDownloads', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }
    
}
