
import { sxAjax } from '@/commons/ajax';
export default {
    initialState: {
        loading: false,// 全局loading
        deviceId: null,//设备id  
        adminList: [],
        deviceEditVisible: false,
        device: null,//设备信息
        code: 0

    },
    getDataByDeviceId: {

    },
    setEditDeviceId: (deviceId) => ({ deviceId }),
    getList: {
        payload: ({ params, options }) => sxAjax.get('/agent/userResource/getResourceTypesRec', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }


    },
    //获取资源
    getResources: {
        payload: ({ params, options }) => sxAjax.get('/agent/userResource/getResources', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }

            },
            complete: (state, action) => ({ loading: false }),
        }

    },
    getDataById: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/getDeviceById', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    device: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    }
    ,
    editData: {
        payload: ({ params, options }) => sxAjax.post('/agent/userResource/editResource', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    loveOrNotLove: {
        payload: ({ params, options }) => sxAjax.get('/agent/userResource/collectResource', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return state

            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    deleteInfo: {
        payload: ({ params, options }) => sxAjax.get('/agent/userResource/delResources', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //素材资源添加
    addResource: {
        payload: ({ params, options }) => sxAjax.post('/agent/userResource/addResource', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {

                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    delResourceType: {
        payload: ({ params, options }) => sxAjax.post('/agent/userResource/delResourceType', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {

                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    editAddResourceType: {
        payload: ({ params, options }) => sxAjax.post('/agent/userResource/editAddResourceType', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {

                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //素材资源审核
    resourceCheck: {
        payload: ({ params, options }) => sxAjax.post('/agent/userResource/resourceCheck', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp);
                } else {

                    reject(resp);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //素材资源审核
    oneKeyPublish: {
        payload: ({ params, options }) => sxAjax.post('/agent/programManage/oneKeyPublish', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {

                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getResourcesById: {
        payload: ({ params, options }) => sxAjax.get('/agent/userResource/getResources', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {

                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return state
            },
            complete: (state, action) => ({ loading: false }),
        }
    },






}
