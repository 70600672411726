import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Icon } from 'antd';
import Link from '../page-link';
import './style.less';

const Item = Breadcrumb.Item;

export default class BreadcrumbComponent extends Component {
    static propTypes = {
        dataSource: PropTypes.array, // 数据源
    };

    static defaultProps = {
        dataSource: [],
    };

    renderItems() {
        const { dataSource } = this.props;
        const iconStyle = { marginRight: 4 };
        if (dataSource && dataSource.length) {
            let itemObjArr = [];
            if (dataSource.length === 2) {
                itemObjArr.push(dataSource[1]);
            } else {
                itemObjArr.push(dataSource[0]);
            }

            return itemObjArr.map(({ key, icon, text, path }) => {
                if (path) {
                    return (
                        <Item key={key}>
                            {/* 头部按钮 */}
                            <Link >
                                {icon ? <Icon type={icon} style={iconStyle} /> : null}
                                {text}
                            </Link>
                        </Item>
                    );
                }
                return (
                    <Item key={key}>
                        {icon ? <Icon type={icon} style={iconStyle} /> : null}
                        {text}
                    </Item>
                );
            })
        }
        return null;
    }

    render() {
        const { theme } = this.props;
        return (
            <div styleName="breadcrumb" className={`system-breadcrumb-${theme}`}>
                <Breadcrumb>
                    {this.renderItems()}
                </Breadcrumb>

            </div>
        );
    }
}
