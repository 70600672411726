import { sxAjax } from '@/commons/ajax';

export const PAGE_FRAME_LAYOUT = {
    TOP_SIDE_MENU: 'top-side-menu',
    TOP_MENU: 'top-menu',
    SIDE_MENU: 'side-menu',
};

export default {
    initialState: {
        pageFrameLayout: PAGE_FRAME_LAYOUT.SIDE_MENU,
        pageHeadFixed: true,
        pageHeadShow: true,
        tabsShow: true,
        loading: false
    },

    syncStorage: true, // 全部同步到localStorage中

    setPageFrameLayout: (pageFrameLayout) => ({ pageFrameLayout }),
    setPageHeadFixed: (pageHeadFixed) => ({ pageHeadFixed: !!pageHeadFixed }),
    showPageHead: (pageHeadShow, state) => ({ pageHeadShow: !!pageHeadShow, pageHeadFixed: pageHeadShow ? state.pageHeadFixed : false }),
    showTabs: tabsShow => ({ tabsShow }),
    // 获取终端列表
    getDeviceGroups: {
        payload: ({ params, options }) => sxAjax.get('/agent/deviceGroup/getDeviceGroups', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 删除终端
    delDeviceGroup: {
        payload: ({ params, options }) => sxAjax.get('/agent/deviceGroup/delDeviceGroup', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 添加修改终端
    editAddDeviceGroup: {
        payload: ({ params, options }) => sxAjax.get('/agent/deviceGroup/editAddDeviceGroup', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

    // 分辨率列表
    getDpis: {
        payload: ({ params, options }) => sxAjax.get('/agent/programDpiManage/getDpis', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 分辨率添加
    editAddDpis: {
        payload: ({ params, options }) => sxAjax.post('/agent/programDpiManage/editAddDpis', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //删除分辨率
    delAddDpis: {
        payload: ({ params, options }) => sxAjax.post('/agent/programDpiManage/delAddDpis', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 音量策略查询列表
    getVolumePolicys: {
        payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyVolume/getVolumePolicys', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 电源策略
    getPowerPolicys: {
        payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyPower/getPowerPolicys', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 下载策略
    getDownloadPolicys: {
        payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyDownload/getDownloadPolicys', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 终端选择音量策略
    addDeviceVolumePolicy: {
        payload: ({ params, options }) => sxAjax.post('/agent/device/addDeviceVolumePolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 电源策略
    addDevicePowerPolicy: {
        payload: ({ params, options }) => sxAjax.post('/agent/device/addDevicePowerPolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 下载策略
    addDeviceDownloadPolicy: {
        payload: ({ params, options }) => sxAjax.post('/agent/device/addDeviceDownloadPolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 终端批量编辑---或-----移动
    editBatchDevices: {
        payload: ({ params, options }) => sxAjax.post('/agent/device/editBatchDevices', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 终端批量 或单个 解绑
    batchUnBindDevices: {
        payload: ({ params, options }) => sxAjax.post('/agent/device/batchUnBindDevices', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //终端命令控制
    batchCommand: {
        payload: ({ params, options }) => sxAjax.post('/agent/device/batchCommand', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //组织结构
    getOrganizeTree: {
        payload: ({ params, options }) => sxAjax.get('/agent/userOrganize/getOrganizeTree', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //删除/批量删除组织结构
    delOrganize: {
        payload: ({ params, options }) => sxAjax.post('/agent/userOrganize/delOrganize', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //编辑组织结构
    editOrganize: {
        payload: ({ params, options }) => sxAjax.post('/agent/userOrganize/editOrganize', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //添加组织结构
    addOrganize: {
        payload: ({ params, options }) => sxAjax.get('/agent/userOrganize/addOrganize', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //组织人员记录
    getOrganizeAgentUsers: {
        payload: ({ params, options }) => sxAjax.get('/agent/userOrganize/getOrganizeAgentUsers', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //删除用户
    delAddOrganizeAgentUser: {
        payload: ({ params, options }) => sxAjax.get('/agent/userOrganize/delAddOrganizeAgentUser', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //修改密码
    deitOrganizePassword: {
        payload: ({ params, options }) => sxAjax.post('/agent/userOrganize/deitOrganizePassword', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //添加/编辑组织用户
    editAddOrganizeAgentUser: {
        payload: ({ params, options }) => sxAjax.post('/agent/userOrganize/editAddOrganizeAgentUser', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },



    //查看角色列表
    getAgentRoles: {
        payload: ({ params, options }) => sxAjax.get('/agent/userOrganize/getAgentRoles', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //查看角色列表
    delAgentRole: {
        payload: ({ params, options }) => sxAjax.get('/agent/userOrganize/delAgentRole', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //角色编辑
    editAgentRole: {
        payload: ({ params, options }) => sxAjax.post('/agent/userOrganize/editAgentRole', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //角色权限查询
    getPermissionsRecAll: {
        payload: ({ params, options }) => sxAjax.get('/agent/userOrganize/getPermissionsRecAll', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //角色授权
    addRolePermission: {
        payload: ({ params, options }) => sxAjax.post('/agent/userOrganize/addRolePermission', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //公司基本信息查看
    getCompanyInfo: {
        payload: ({ params, options }) => sxAjax.get('/agent/companyInfo/getCompanyInfo', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //角色添加
    roleAdd: {
        payload: ({ params, options }) => sxAjax.post('/agent/userOrganize/roleAdd', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
}
