
import { sxAjax } from '@/commons/ajax';

export default {
    
    pageList: {
                payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyPower/getPowerPolicys', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            },//编辑/添加电源策略
             editAddPowerPolicy: {
                payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyPower/editAddPowerPolicy', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }
            }, //查询电源周策略
            getPowerWeekPolicy: {
               payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyPower/getPowerWeekPolicy', params, options).then(function (resp) {
                   return new Promise((resolve, reject) => {
                       if (resp.code === 0) {
                           resolve(resp.data);
                       } else {
                           reject(resp.msg);
                       }
                   });
               }),
               reducer: {
                   pending: (state, action) => {
                       return { loading: true };
                   },
                   resolve(state, { payload = {} }) {
                       return {
                          adminList: payload
                       }
                   },
                   complete: (state, action) => ({ loading: false }),
               }
           }, //编辑电源周策略
           editPowerWeekPolicy: {
              payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyPower/editPowerWeekPolicy', params, options).then(function (resp) {
                  return new Promise((resolve, reject) => {
                      if (resp.code === 0) {
                          resolve(resp.data);
                      } else {
                          reject(resp.msg);
                      }
                  });
              }),
              reducer: {
                  pending: (state, action) => {
                      return { loading: true };
                  },
                  resolve(state, { payload = {} }) {
                      return {
                         adminList: payload
                      }
                  },
                  complete: (state, action) => ({ loading: false }),
              }
          },  //编辑电源周策略
          addPowerWeekPolicy: {
             payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyPower/addPowerWeekPolicy', params, options).then(function (resp) {
                 return new Promise((resolve, reject) => {
                     if (resp.code === 0) {
                         resolve(resp.data);
                     } else {
                         reject(resp.msg);
                     }
                 });
             }),
             reducer: {
                 pending: (state, action) => {
                     return { loading: true };
                 },
                 resolve(state, { payload = {} }) {
                     return {
                        adminList: payload
                     }
                 },
                 complete: (state, action) => ({ loading: false }),
             }
         },  //删除 电源 周策略
         delPowerWeekPolicy: {
            payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyPower/delPowerWeekPolicy', params, options).then(function (resp) {
                return new Promise((resolve, reject) => {
                    if (resp.code === 0) {
                        resolve(resp.data);
                    } else {
                        reject(resp.msg);
                    }
                });
            }),
            reducer: {
                pending: (state, action) => {
                    return { loading: true };
                },
                resolve(state, { payload = {} }) {
                    return {
                       adminList: payload
                    }
                },
                complete: (state, action) => ({ loading: false }),
            }
        },//查询电源特殊日期策略
        getPowerSpecialPolicy: {
           payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyPower/getPowerSpecialPolicy', params, options).then(function (resp) {
               return new Promise((resolve, reject) => {
                   if (resp.code === 0) {
                       resolve(resp.data);
                   } else {
                       reject(resp.msg);
                   }
               });
           }),
           reducer: {
               pending: (state, action) => {
                   return { loading: true };
               },
               resolve(state, { payload = {} }) {
                   return {
                      adminList: payload
                   }
               },
               complete: (state, action) => ({ loading: false }),
           }
       },//删除 电源 特殊 日期 策略
       delPowerSpecialPolicy: {
          payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyPower/delPowerSpecialPolicy', params, options).then(function (resp) {
              return new Promise((resolve, reject) => {
                  if (resp.code === 0) {
                      resolve(resp.data);
                  } else {
                      reject(resp.msg);
                  }
              });
          }),
          reducer: {
              pending: (state, action) => {
                  return { loading: true };
              },
              resolve(state, { payload = {} }) {
                  return {
                     adminList: payload
                  }
              },
              complete: (state, action) => ({ loading: false }),
          }
      },//添加电源特殊日期策略
      addPowerSpecialPolicy: {
         payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyPower/addPowerSpecialPolicy', params, options).then(function (resp) {
             return new Promise((resolve, reject) => {
                 if (resp.code === 0) {
                     resolve(resp.data);
                 } else {
                     reject(resp.msg);
                 }
             });
         }),
         reducer: {
             pending: (state, action) => {
                 return { loading: true };
             },
             resolve(state, { payload = {} }) {
                 return {
                    adminList: payload
                 }
             },
             complete: (state, action) => ({ loading: false }),
         }
     },//编辑电源特殊日期策略
     editPowerSpecialPolicy: {
        payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyPower/editPowerSpecialPolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                   adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },//批量删除 /单个删除策略
    delPowerPolicy: {
       payload: ({ params, options }) => sxAjax.get('/agent/devicePolicyPower/delPowerPolicy', params, options).then(function (resp) {
           return new Promise((resolve, reject) => {
               if (resp.code === 0) {
                   resolve(resp.data);
               } else {
                   reject(resp.msg);
               }
           });
       }),
       reducer: {
           pending: (state, action) => {
               return { loading: true };
           },
           resolve(state, { payload = {} }) {
               return {
                  adminList: payload
               }
           },
           complete: (state, action) => ({ loading: false }),
       }
    },
    //查看电源策略 策略的id
    getPolicyPowers: {
       payload: ({ params, options }) => sxAjax.get('/agent/device/getPolicyPowers', params, options).then(function (resp) {
           return new Promise((resolve, reject) => {
               if (resp.code === 0) {
                   resolve(resp.data);
               } else {
                   reject(resp.msg);
               }
           });
       }),
       reducer: {
           pending: (state, action) => {
               return { loading: true };
           },
           resolve(state, { payload = {} }) {
               return {
                  adminList: payload
               }
           },
           complete: (state, action) => ({ loading: false }),
       }
   },
    
}
