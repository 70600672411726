
import { sxAjax } from '@/commons/ajax';



export default {
    pageList: {
                payload: ({ params, options }) => sxAjax.get('agent/deviceGroup/getDeviceGroups', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }


            }, 
    //删除设备
    delData: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/delLogicDevices', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                 
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                 
                return {
                    adminList: payload
                 }
                 
            },
            complete: (state, action) => ({ loading: false }),
        }

    },
    getDataById:{
        payload: ({ params, options }) => sxAjax.get('/agent/device/getDeviceById', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    device: payload
                 }
                
                 
            },
            complete: (state, action) => ({ loading: false }),
        }
    }
    ,
    editData:{
        payload: ({ params, options}) => sxAjax.get('/agent/device/editDevices', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                 
                return {
                     code: payload.code
                 }
                
                 
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getCompanyDeviceTree:{
        payload: ({ params, options}) => sxAjax.get('agent/device/getCompanyDeviceTree', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                 
                return {
                     code: payload.code
                 }
                
                 
            },
            complete: (state, action) => ({ loading: false }),
        }
    }
    



    
}
