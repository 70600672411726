
import { sxAjax } from '@/commons/ajax';


// 进行本地存储同步，syncState中的同步是区分用户的，会导致未登录的页面与登录的页面有差异

export default {
    //充值记录（收支记录）
    orderRechargesPageList: {
        payload: ({ params, options }) => sxAjax.get('/agent/userAssetCenter/getOrderRecharges', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }


    }, //终端续费记录
    getOrderTerminals: {
        payload: ({ params, options }) => sxAjax.get('/agent/userAssetCenter/getOrderTerminals', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    }, //模版购买费用记录
    getOrderTemplates: {
        payload: ({ params, options }) => sxAjax.get('/agent/userAssetCenter/getOrderTemplates', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    }, //购买空间记录
    getOrderDisks: {
        payload: ({ params, options }) => sxAjax.get('/agent/userAssetCenter/getOrderDisks', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    }, //流量明细
    getFlowDetails: {
        payload: ({ params, options }) => sxAjax.get('/agent/userAssetCenter/getFlowDetails', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    }, //购买流量记录
    getOrderFlows: {
        payload: ({ params, options }) => sxAjax.get('/agent/userAssetCenter/getOrderFlows', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

    buyDeviceOrder: {
        payload: ({ params, options }) => sxAjax.get('/agent/userAssetCenter/getDeviceNumOrders', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

}
