import SXAjax, { createAjaxHoc } from '@/library/ajax';
import mockUrls from '../mock/url-config';
import handleError from './handle-error';
import handleSuccess from './handle-success';
import configEnv from './configEnv';
/**
 * 判断请求是否是mock
 * @param url
 * @returns {boolean|*}
 */
export function isMock(url /* url, data, method, options */) {
    return mockUrls.indexOf(url) > -1 || url.startsWith('/mock');
}

/**
 * ajax工具，含有errorTip 和 successTip
 * @type {SXAjax}
 */
export const sxAjax = new SXAjax({
    onShowErrorTip: (error, errorTip) => handleError({ error, errorTip }),
    onShowSuccessTip: (response, successTip) => handleSuccess({ successTip }),
    isMock,
});

// 默认配置   发布到线上的时候需要改成这样sxAjax.defaults.baseURL = '/api/';本地开发sxAjax.defaults.baseURL = 'http://localhost:10007';

let winConfig = configEnv
sxAjax.defaults.baseURL = winConfig && winConfig.official
    ? window.location.href.split('//')[0] + '//' + winConfig.ajaxDefaults + '/api/'
    : '/api/';

//编辑器跳转路径
window.sessionStorage.setItem('to_h5edit_url', '/page/adpublish-edit/index.html');
let urlArr = window.location.href.split('/');
const h5edit_base_pre_url = urlArr[0] + '//' + urlArr[2] + sxAjax.defaults.baseURL;
window.localStorage.setItem('h5edit_base_pre_url', h5edit_base_pre_url);

// 默认配置
sxAjax.defaults.timeout = 1000 * 60;
sxAjax.mockDefaults.baseURL = '/';
sxAjax.crossDomain = true;
sxAjax.defaults.crossDomain = true;
// sxAjax.headers["access-token"] = localStorage.getItem("") || "";
;

/**
 * ajax高阶组件
 */
export const ajaxHoc = createAjaxHoc(sxAjax);

/**
 * ajax工具，不含有 errorTip和successTip
 * @type {SXAjax}
 */
export const ajax = new SXAjax({
    isMock,
});

// 默认配置
ajax.defaults.baseURL = '';
ajax.defaults.timeout = 1000 * 5;

// 请求前拦截
[ajax.instance, sxAjax.instance].forEach(instance => {
    instance.interceptors.request.use(cfg => {
        // Do something before request is sent
        return cfg;
    }, error => {
        console.log(error)
        // Do something with request error
        return Promise.reject(error);
    });
});


/**
 * mockjs使用的axios实例
 */
export const mockInstance = ajax.mockInstance = sxAjax.mockInstance;
