import React, { Component } from 'react';
import { connect } from '@/models';
import './style.less';
import { message, Empty, Icon, Badge } from 'antd';
import { Link } from 'react-router-dom';
import Contact from './contact';
import UserMessage from './userMessage';
@connect(state => ({ local: state.system.i18n }))
export default class HeaderUs extends Component {
    static defaultProps = {
        theme: 'default',
    };
    state = {
        isInform: false,
        contactVisible: false,
        userMessageVisible: false,
        sysMessageNoReadNum: 1, //系统通知 未读数量
        noReadAllNum: 2, //未读消息总数
        readMessages: null
    };
    componentDidMount() {
        this.refreshReadMessage()
        this.getSystemCompanyMessages()
        this.timeNum = setInterval(() => { this.refreshReadMessage(); this.getSystemCompanyMessages() }, 300000)
        this.timer = setInterval(() => {
            let saved = parseInt(localStorage.getItem("needRequestManage"));
            if (saved !== 0) {
                this.refreshReadMessage();
                this.getSystemCompanyMessages();
                localStorage.setItem("needRequestManage", 0);
            }
        }, 1000);
    }
    //页面卸载清除定时器
    componentWillUnmount() {
        clearInterval(this.timeNum)
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null;
        }
    }
    refreshReadMessage = () => {
        this.props.action.common.refreshReadMessage({
            params: {},
            options: { method: "GET", withCredentials: false, contentType: "application/json" },
            onResolve: (data) => {
                this.setState({
                    sysMessageNoReadNum: data.sysMessageNoReadNum, //系统通知 未读数量
                    noReadAllNum: data.noReadAllNum, //未读消息总数
                    isInform: data.sysMessageNoReadNum > 0 ? true : false
                })
            },
            onReject: (data) => {
                message.error(data.msg);
            },
        });
    }
    getSystemCompanyMessages = () => {
        this.props.action.common.getSystemCompanyMessages({
            params: {
                isRead: 0,
                page: 1,
                pageSize: 8
            },
            options: { method: "GET", withCredentials: false, contentType: "application/json" },
            onResolve: (data) => {
                let arr = data.list.reverse()
                this.setState({
                    readMessages: arr
                })
            },
            onReject: (data) => {
                message.error(data);
            },
        });
    }
    beenRead = (id) => {

        const { readMessages } = this.state
        let ids
        if (id !== undefined) {
            ids = id
        } else {
            if (readMessages.length === 0) {
                return
            }
            let arr = []
            readMessages.forEach(item => {
                arr.push(item.id)
            })
            ids = arr.join(',')
        }
        this.props.action.common.beenRead({
            params: { ids: ids },
            options: { method: "GET", withCredentials: false, contentType: "application/json" },
            onResolve: (data) => {
                this.refreshReadMessage()
                this.getSystemCompanyMessages()
                let href = window.location.href;
                if (href.indexOf('/user-inform') !== -1) {
                    this.props.action.system.refreshTab('/user-inform')
                }
            },
            onReject: (data) => {
                message.error(data);
            },
        });
    }
    render() {
        const { isInform, contactVisible, userMessageVisible, sysMessageNoReadNum, readMessages } = this.state
        return (
            <div >
                {/* <div styleName='box contact' onClick={()=>{this.setState({ contactVisible:true })}}>
                    <span></span>
                    <span>联系我们</span>
                </div>
                <Link to='/user-manual'>
                    <div styleName='box manual'>
                        <span></span>
                        <span>用户手册</span>
                    </div>
                </Link>
                <div styleName='box message' onClick={()=>{this.setState({ userMessageVisible:true })}}>
                    <span></span>
                    <span>我要留言</span>
                </div> */}
                <div styleName='inform' className='informClass' style={isInform ? { background: '#FFF' } : {}}>
                    {/* {
                        isInform ?
                            <img src={require('../../pages/img/inform3.png')} alt="" />
                            : <div styleName='informImg'></div>
                    } */}
                    <span style={{ display: 'none' }}></span>
                    <Badge count={sysMessageNoReadNum} dot={true}>
                        <span style={isInform ? { color: '#FF4949' } : {}}>系统通知</span>
                        <Icon type="bell" theme="filled" style={{ fontSize: 18, marginLeft: 5 }} />
                    </Badge>


                    {
                        isInform ?
                            <span styleName='inform-badge'>{sysMessageNoReadNum > 9 ? '9+' : sysMessageNoReadNum}</span>
                            : <span></span>
                    }
                    <div styleName='informMessages'>
                        <div styleName='informTitle'>
                            <div style={{ borderBottom: '1px solid #3491FB' }}>全部通知</div>
                            <div><img src={require('../../pages/img/duihao.png')} onClick={() => { this.beenRead() }} alt="" title='标为已读' /></div>
                        </div>
                        <div styleName='test'>
                            {
                                readMessages ?
                                    readMessages.length > 0 ?
                                        readMessages.map(item => {
                                            return (
                                                <div styleName='test-box' key={item.id} onClick={() => { this.beenRead(item.id) }}>
                                                    <div styleName='test-box-content'>
                                                        <div>
                                                            {
                                                                item.isRead ? <img style={{ display: 'inline-block', width: '13px', height: '16px', marginTop: '-3px' }} src={require('../../pages/img/read.png')} alt=""></img>
                                                                    : <span style={{ display: 'inline-block', width: '10px', height: '10px', marginRight: '5px', borderRadius: '50%', background: '#FF0201' }}></span>
                                                            }
                                                            <span>{item.title}</span>
                                                        </div>
                                                        <div style={{ fontSize: '12px', color: '#989AA0', marginLeft: '15px' }}>{item.message}</div>
                                                    </div>
                                                    <div styleName='test-box-time'>
                                                        {item.createTime}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : <Empty style={{ marginTop: '55px' }} />
                                    : <Empty style={{ marginTop: '55px' }} />
                            }
                        </div>
                        <div style={{ textAlign: 'center' }}><Link to='/user-inform' style={{ color: '#343434' }}>
                            <span style={{ verticalAlign: 'middle' }}>查看全部消息</span>
                            <img style={{ display: 'inline-block', width: '12px', height: '10px', marginLeft: '3px' }} src={require('../../pages/img/towNext2.png')} alt="" />
                        </Link></div>
                    </div>
                </div>

                {/* 联系我们 */}
                {
                    contactVisible ?
                        <Contact
                            visible={contactVisible}
                            onOk={() => { this.setState({ contactVisible: false }); }}
                        /> : null
                }
                {/* 用户留言 */}
                <UserMessage
                    visible={userMessageVisible}
                    onOk={() => { this.setState({ userMessageVisible: false }); }}
                />
            </div>
        );
    }
}
