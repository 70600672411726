// 此文件为生成文件，不要直接编辑
import _approveSet from 'F:/XINFA/web/yongHuHouTai/src/models/approveSet.js';
import _checkManage from 'F:/XINFA/web/yongHuHouTai/src/models/checkManage.js';
import _common from 'F:/XINFA/web/yongHuHouTai/src/models/common.js';
import _companySet from 'F:/XINFA/web/yongHuHouTai/src/models/companySet.js';
import _device from 'F:/XINFA/web/yongHuHouTai/src/models/device.js';
import _deviceGroup from 'F:/XINFA/web/yongHuHouTai/src/models/deviceGroup.js';
import _deviceManage from 'F:/XINFA/web/yongHuHouTai/src/models/deviceManage.js';
import _disk from 'F:/XINFA/web/yongHuHouTai/src/models/disk.js';
import _dpiSet from 'F:/XINFA/web/yongHuHouTai/src/models/dpiSet.js';
import _fileUploud from 'F:/XINFA/web/yongHuHouTai/src/models/fileUploud.js';
import _home from 'F:/XINFA/web/yongHuHouTai/src/models/home.js';
import _menu from 'F:/XINFA/web/yongHuHouTai/src/models/menu.js';
import _orderManage from 'F:/XINFA/web/yongHuHouTai/src/models/orderManage.js';
import _page from 'F:/XINFA/web/yongHuHouTai/src/models/page.js';
import _playplan from 'F:/XINFA/web/yongHuHouTai/src/models/playplan.js';
import _program from 'F:/XINFA/web/yongHuHouTai/src/models/program.js';
import _programManage from 'F:/XINFA/web/yongHuHouTai/src/models/programManage.js';
import _programPlayLogs from 'F:/XINFA/web/yongHuHouTai/src/models/programPlayLogs.js';
import _routers from 'F:/XINFA/web/yongHuHouTai/src/models/routers.js';
import _settings from 'F:/XINFA/web/yongHuHouTai/src/models/settings.js';
import _shopping from 'F:/XINFA/web/yongHuHouTai/src/models/shopping.js';
import _side from 'F:/XINFA/web/yongHuHouTai/src/models/side.js';
import _sourceMaterial from 'F:/XINFA/web/yongHuHouTai/src/models/sourceMaterial.js';
import _downLoadStrategy from 'F:/XINFA/web/yongHuHouTai/src/models/strategy/downLoadStrategy.js';
import _luminanceStrategy from 'F:/XINFA/web/yongHuHouTai/src/models/strategy/luminanceStrategy.js';
import _powerStrategy from 'F:/XINFA/web/yongHuHouTai/src/models/strategy/powerStrategy.js';
import _volumeStrategy from 'F:/XINFA/web/yongHuHouTai/src/models/strategy/volumeStrategy.js';
import _sysConfig from 'F:/XINFA/web/yongHuHouTai/src/models/sysConfig.js';
import _system from 'F:/XINFA/web/yongHuHouTai/src/models/system.js';
import _usercenter from 'F:/XINFA/web/yongHuHouTai/src/models/usercenter.js';
import _userorganization from 'F:/XINFA/web/yongHuHouTai/src/models/userorganization.js';

export const approveSet = _approveSet;
export const checkManage = _checkManage;
export const common = _common;
export const companySet = _companySet;
export const device = _device;
export const deviceGroup = _deviceGroup;
export const deviceManage = _deviceManage;
export const disk = _disk;
export const dpiSet = _dpiSet;
export const fileUploud = _fileUploud;
export const home = _home;
export const menu = _menu;
export const orderManage = _orderManage;
export const page = _page;
export const playplan = _playplan;
export const program = _program;
export const programManage = _programManage;
export const programPlayLogs = _programPlayLogs;
export const routers = _routers;
export const settings = _settings;
export const shopping = _shopping;
export const side = _side;
export const sourceMaterial = _sourceMaterial;
export const downLoadStrategy = _downLoadStrategy;
export const luminanceStrategy = _luminanceStrategy;
export const powerStrategy = _powerStrategy;
export const volumeStrategy = _volumeStrategy;
export const sysConfig = _sysConfig;
export const system = _system;
export const usercenter = _usercenter;
export const userorganization = _userorganization;

