import { sxAjax } from '@/commons/ajax';
export default {
    initialState: {
        loading: false,             // 全局loading
    },

    // 计划列表
    getProgramPlayPlans: {
        payload: ({ params, options }) => sxAjax.get('/agent/programPlayPlan/getProgramPlayPlans', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //编辑添加计划列表
    editAddPlayPlans: {
        payload: ({ params, options }) => sxAjax.post('/agent/programPlayPlan/editAddPlayPlans', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 删除计划
    delPlayPlans: {
        payload: ({ params, options }) => sxAjax.post('/agent/programPlayPlan/delPlayPlans', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 播放计划绑定终端
    addPlayPlanBindDevice: {
        payload: ({ params, options }) => sxAjax.post('/agent/programPlayPlan/addPlayPlanBindDevice', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 根据播放计划查看终端信息
    getDevicesByPlayPlanId: {
        payload: ({ params, options }) => sxAjax.get('/agent/programPlayPlan/getDevicesByPlayPlanId', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 播放计划解绑终端
    addPlayPlanUnBindDevice: {
        payload: ({ params, options }) => sxAjax.get('/agent/programPlayPlan/addPlayPlanUnBindDevice', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 播放计划解绑终端
    getPlayPlanSchedules: {
        payload: ({ params, options }) => sxAjax.get('/agent/programPlayPlan/getPlayPlanSchedules', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    // 播放计划解绑终端
    delPlayPlanSchedule: {
        payload: ({ params, options }) => sxAjax.get('/agent/programPlayPlan/delPlayPlanSchedule', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    }, // 节目
    getPrograms: {
        payload: ({ params, options }) => sxAjax.get('/agent/programManage/getPrograms', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //编辑/添加计划排期/agent/programPlayPlan/editAddPlayPlanSchedule
    editAddPlayPlanSchedule: {
        payload: ({ params, options }) => sxAjax.get('/agent/programPlayPlan/editAddPlayPlanSchedule', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
}