import { passwordRex } from "@/commons/regexp";
 
export default {
    application: {
        name: '信息发布系统',
    },
    ajaxTip: {
        success: '成功',
        error: '失败',
        noAccess: '无权访问此资源',
        notFound: '访问资源不存在',
        serverBusy: '服务器繁忙',
        timeOut: '超时',
    },
    menu: {
        home: '首页',
        template: '模板',
        program: '节目',
        playplan: '播放计划',
        terminal: '设备管理',
        material: '素材',
        setting: '设置',
        usercenter: "用户中心",
        adminManage: "设备管理",
        schedule: "待办事项",
        capitalassets: "资产管理",
        modifyPassword: "修改密码",
        logout: "退出登录"
    },
    login: {
        title: '欢迎登录',
        submit: '登录',
        userName: '账号',
        password: '密码',
        userNameEmptyTip: '请输入账号',
        passwordEmptyTip: '请输入密码',
        passwordIsOk: '请输入确认密码',
        passwordSign: '请输入密码',
        phone: "手机号不能为空",
        code: "验证码不能为空",
        nickname: "公司名称不能为空",
        pass: "密码不能为空",
        pwdMessage: passwordRex.msg,
        newpassempty: "新密码不能为空",
        newpass: "两次密码不一致",
        getcode: "获取验证码",
        format: "手机号格式不正确",
        chongfa: "秒后重发",
        noregister: "没有账号？立即注册",
        forgetpassword: "忘记密码？",
        Mobile: "手机号",
        verificationcode: "验证码",
        petname: "昵称",
        Quickregistration:"快速注册",
        xiugaimima:"修改密码",
        jiumima:"旧密码",
        xinmima:"新密码",
        zaicishuruxinmima:"再次输入新密码",
        xiugaichenggong:"修改成功",
        xiugaishoujihao:"修改手机号",
        dengluzhanghao:"登录账号",
        gongsiid:"公司id",
        yonghunicheng:"用户昵称",
        chuangjianshijian:"创建时间",
        gongsimingcheng:"公司名称",
        denglumima:"登录密码",
        jianyi:"建议使用字母数字组合的密码",
        shezhimima:"设置密码",
        shezhishoujihao:"修改/设置手机号",
        yibangding:"已绑定",
        weibangding:"未绑定",
        zhanghuanquan:"账户安全",
        yonghuxinxi:"用户信息"
    },
    setting: {
        nameempty:"名称不能为空",
        heightempty:"高度不能为空",
        widthempty:"宽度不能为空",
        resolutionratio:"分辨率",
        name:"名称",
        opteration:"操作",
        compile:"编辑",
        delete:"删除",
        newz:"新增",
        compileratio:"编辑分辨率",
        sheizhifenbianlvmingcheng:"设置分辨率名称",
        shezhigaodu:"设置高度",
        shezhikuandu:"设置宽度",
        xinzengfenbianlv:"新增分辨率",
        bianma:"编码",
        bianjizhongduanmingcheng:"编辑终端名称",
        xinzengzhongduan: "新增终端",
        selectPrimaryColor: '选择主题颜色',
        fullScreen: '全屏',
        exitFullScreen: '退出全屏',
    },
    tabs: {
        refresh: '刷新',
        refreshAll: '刷新全部',
        close: '关闭',
        closeOthers: '关闭其他',
        closeAll: '关闭所有',
        closeLeft: '关闭左侧',
        closeRight: '关闭右侧',
    },
    errorPage: {
        needLogin: '您还未登录！',
        pageNotFound: '您访问的页面不存在！',
        redirectTo: '跳转到',
        orReturn: '或者返回',
        previousStep: '上一步',
    },
};
