import React, {Component} from 'react';
import { getLoginUser } from '@/commons';
import {Modal, Form, message} from 'antd';
import config from '@/commons/config-hoc';
import './style.less';
@config({
    ajax: true,
    connect(state) {
        return {};
    }
})
@Form.create()
export default class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content:null,//内容
            loading: false,
            visible:false
        };
    }


    componentDidMount() {
        this.setState({visible:true})
        this.props.action.common.getAgreement({
            params: {agentUserId: getLoginUser().id, type:'1'},
            options: { method: "GET", withCredentials: false, contentType: "application/json" },
            onResolve: (data) => {
                this.setState(
                    {content: data.content}
                )
            },
            onReject: (data) => {
                message.error(data);
            },
        });
    }
    componentDidUpdate() {
    }
    ok = () => {
        const { onOk } = this.props;
        this.setState({visible:false});
        setTimeout(()=>onOk(), 1000);
    }
    render() {
        const {visible} = this.state;
        return (
            <Modal
                maskClosable={false}
                destroyOnClose
                width="22%"
                visible={visible}
                bodyStyle={{padding:0,height:'460PX'}}
                onOk={()=>this.ok()}
                onCancel={() => this.ok()}
                footer={null}
            >
                <div>
                    <div style={{borderBottom:'1px solid #C2DEFE'}}>
                        <div style={{
                            borderBottom: '1px solid #3491FB',
                            padding: '16px 0 12px 16px',
                            width: '95px'
                        }}>联系我们</div>
                    </div>
                    <div
                        styleName='test'
                        style={{
                            padding:'0 20px',
                            height:'320px',
                            fontSize:'14px',
                            fontWeight:'400',
                            lineHeight: '27px',
                            margin: 'auto',
                            marginTop: '50px',
                            overflow: 'auto',
                            boxSizing:'border-box'
                    }}
                        >
                        <div dangerouslySetInnerHTML = {{ __html: this.state.content}}></div>
                    </div>
                    
               </div>
            </Modal>
        );
    }
}
