
import { sxAjax } from '@/commons/ajax';


// 进行本地存储同步，syncState中的同步是区分用户的，会导致未登录的页面与登录的页面有差异

export default {
    pageList: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/getDevices', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    adminList: payload
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //删除设备
    del: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/delLogicDevices', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {

                return {
                    adminList: payload
                }

            },
            complete: (state, action) => ({ loading: false }),
        }

    },
    getDataById: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/getDeviceById', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    device: payload
                }


            },
            complete: (state, action) => ({ loading: false }),
        }
    }
    ,
    edit: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/editDevices', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {

                return {
                    code: payload
                }


            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getCompanyDeviceTree: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/getCompanyDeviceTree', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {

                return {
                    code: payload.code
                }


            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    addDeviceComboRenew: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/addDeviceComboRenew', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    addDeviceVolumePolicy: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/addDeviceVolumePolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    addDeviceDownloadPolicy: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/addDeviceDownloadPolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    addDevicePowerPolicy: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/addDevicePowerPolicy', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    batchCommand: {
        payload: ({ params, options }) => sxAjax.post('/agent/device/batchCommand', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    batchUnBindDevices: {
        payload: ({ params, options }) => sxAjax.post('/agent/device/batchUnBindDevices', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    editBatchDevices: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/editBatchDevices', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload?.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //查看电源策略
    getPolicyPowers: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/getPolicyPowers', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //查看电源策略
    getPolicyVolumes: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/getPolicyVolumes', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //下载策略
    getPolicyDownloads: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/getPolicyDownloads', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //终端节目查看

    //计划排期列表 节目查看
    getPlayPlanSchedules: {
        payload: ({ params, options }) => sxAjax.get('/agent/programPlayPlan/getPlayPlanSchedules', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //节目命令操作
    batchProgramCommand: {
        payload: ({ params, options }) => sxAjax.post('/agent/device/batchProgramCommand', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //获取时区
    getTimeZoneEnums: {
        payload: ({ params, options }) => sxAjax.get('/common/status/getTimeZoneEnums', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //添加设备
    addDevice: {
        payload: ({ params, options }) => sxAjax.post('/app/device/addDevice', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload?.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //局域网搜索
    LANSearch: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/getScanDevices', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //显示编号
    serialNumber: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/showTerminalNum', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    //终端截屏
    getScreenshotImgUrl: {
        payload: ({ params, options }) => sxAjax.get('/agent/device/getScreenshotImgUrl', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {

                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
                return {
                    code: payload.code
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    }
}
